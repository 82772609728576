@charset 'UTF-8';

@use '../global/' as *;

ul.list,
ol.list {
  margin-left: 0.2em;
  li {
    margin-bottom: 0.5em;
  }
  ul,
  ol {
    margin-left: 1em;
  }

  &.li-mb {
    li:nth-of-type(n + 2) {
      margin-top: 0.8em;
    }
    ul,
    ol {
      margin-top: 0.8em;
    }
  }

  // インデント
  &.indent {
    margin-left: 0.5em;
    ul,
    ol {
      margin-top: 0.8em;
    }
  }
}

ul.list,
ul.list-row {
  // dotted
  &.disc {
    list-style: disc outside;
    padding-left: 1.125em;
    ul {
      list-style: circle outside;
    }
  }
  // 四角 & 丸
  &.dot,
  &.square,
  &.circle,
  &.circle-white,
  &.kome,
  &.checkbox {
    & > li {
      line-height: inherit;
      padding-left: 1.4em;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        font-family: sans-serif;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.dot {
    & > li {
      padding-left: 1.5rem;
      &::before {
        width: 8px;
        height: 8px;
        background: $primary;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
      }
    }
  }

  &.square {
    & > li {
      &:before {
        content: '■';
      }
    }
  }

  &.circle {
    & > li {
      &:before {
        content: '●';
      }
    }
  }
  &.circle-white {
    & > li {
      &:before {
        content: '○';
      }
    }
  }
  &.kome {
    & > li {
      &:before {
        content: '※';
      }
    }
  }
  &.checkbox {
    & > li {
      &:before {
        font-family: $material-icon-font-family;
        font-weight: 700;
        content: '\e834';
      }
    }
  }
}

ol.list,
ol.list-row {
  li {
    margin-left: 0.5em;
  }
  // 数字
  &.number {
    list-style: decimal outside;
    padding-left: 1.125em;
    ol {
      list-style: decimal outside;
    }
  }
}

dl.list {
  dt {
    font-weight: 700;
    &:nth-of-type(n + 2) {
      margin-top: 1em;
    }
  }
  dd {
    line-height: 1.4;
  }
}

// list の横並び
//
.list-row {
  display: inline-flex;
  flex-flow: column nowrap;
  @include media(md) {
    flex-flow: row wrap;
  }
  &--center {
    width: 100%;
    align-items: center;
  }
  li {
    &:not(:last-child) {
      @include media(md) {
        margin-right: 2rem;
      }
    }
    & + li {
      margin-top: 2rem;
      @include media(md) {
        margin-top: 0;
      }
    }
  }
}

//定義リスト
dl.dl-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  dt {
    color: $secondary;
    font-weight: bold;
    display: block;
    padding-bottom: 0.5em;
    &:nth-of-type(n + 2) {
      margin-top: 1.5em;
    }
    @include media(md) {
      border-bottom: 1px solid $border-color;
      padding-bottom: 1.5em;
      width: 25%;
    }
    @include media(xl) {
      width: 20%;
    }
  }
  dd {
    border-bottom: 1px solid $border-color;
    padding-bottom: 1.5em;
    width: 100%;
    @include media(md) {
      width: 75%;
      padding-left: 2%;
      &:nth-of-type(n + 2) {
        margin-top: 1.5em;
      }
    }
    @include media(xl) {
      width: 80%;
    }
  }

  //定義リストdt 1-20em
  @for $i from 1 through 20 {
    &.dl-list-#{$i} {
      dt {
        @include media(md) {
          width: #{$i}em;
        }
      }
      dd {
        @include media(md) {
          width: calc(100% - #{$i}em);
          padding-left: 2%;
        }
      }
    }
  }
}

// フロー
.flow {
  margin: 3em auto 0;
  &__title {
    margin-bottom: 1.2rem;
    padding: 0 0 0 5.5rem;
    color: $secondary;
    @include media(lg) {
      padding: 5.5rem 0 0;
      margin-bottom: 2.3rem;
    }
  }
  &__text {
    margin: 0 0 0 5.5rem;
    @include media(lg) {
      margin: 0;
      padding: 0;
    }
  }
  & > li {
    position: relative;
    padding-bottom: 3.2rem;
    @include media(lg) {
      padding-bottom: 6rem;
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
        margin-bottom: 5.6rem;
      }
    }
    &:nth-of-type(n) {
      .flow__title {
        &::before,
        &::after {
          position: absolute;
          left: 0;
          text-align: center;
          font-family: $base-font-family-en;
          font-weight: normal;
        }
        &::before {
          content: 'STEP';
          font-size: 1rem;
          top: 0;
          @include media(lg) {
            font-size: 1.8rem;
            top: 0.8rem;
          }
        }
        &::after {
          content: '';
          font-size: 2.4rem;
          top: 1rem;
          @include media(lg) {
            font-size: 2.6rem;
            top: 0;
            left: 6rem;
          }
        }
      }
    }
    &:nth-of-type(1) {
      .flow__title {
        &::after {
          content: '01';
        }
      }
    }
    &:nth-of-type(2) {
      .flow__title {
        &::after {
          content: '02';
        }
      }
    }
    &:nth-of-type(3) {
      .flow__title {
        &::after {
          content: '03';
        }
      }
    }
    &:nth-of-type(4) {
      .flow__title {
        &::after {
          content: '04';
        }
      }
    }
    &:nth-of-type(5) {
      .flow__title {
        &::after {
          content: '05';
        }
      }
    }
    &:nth-of-type(6) {
      .flow__title {
        &::after {
          content: '06';
        }
      }
    }
    &:nth-of-type(7) {
      .flow__title {
        &::after {
          content: '07';
        }
      }
    }
    &:nth-of-type(8) {
      .flow__title {
        &::after {
          content: '08';
        }
      }
    }
    &:nth-of-type(9) {
      .flow__title {
        &::after {
          content: '09';
        }
      }
    }
    &:nth-of-type(10) {
      .flow__title {
        &::after {
          content: '10';
        }
      }
    }
  }
}
