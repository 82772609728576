@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.closure-section-container {
  & + & {
    margin-top: 2rem;
  }
}

.closure-section-name {
  border-top: 1px solid sys.$gray-300;
  border-bottom: 1px solid sys.$gray-300;
  font-weight: 700;
  margin-bottom: 1em;
  padding: 0.5em 1em;
}

.closure-item-list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em;
  li {
    width: 100%;
    padding: 0.5em;
    @include sys.media(md) {
      width: 50%;
    }
    @include sys.media(lg) {
      width: 33.33333%;
    }
  }
}

.closure-item-container {
  border: 1px solid sys.$gray-400;
  padding: 1em;
  height: 100%;
  text-align: center;
  border-radius: 6px;

  &._closure {
    .closure-title {
      background-color: sys.$closure-title-bg-color;
    }
  }
  &._assist {
    .closure-title {
      background-color: sys.$assist-title-bg-color;
    }
  }
}

.closure-title {
  background-color: sys.$gray-800;
  color: sys.$white;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.25em 0.5em;
  letter-spacing: 1px;
  border-radius: 6px;
}

.closure-date {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0.75em 0;
  letter-spacing: 1px;
}

.closure-timezone {
  font-weight: 700;
  span {
    background-color: sys.$white;
    border: 1px solid sys.$primary;
    // color: sys.$primary;
    display: inline-block;
    font-size: 0.875em;
    padding: 0.125em 0.5rem;
  }
}

.closure-doctor {
  font-weight: 700;
  margin-top: 0.5em;
  margin-bottom: 1rem;
}

.closure-comment {
  font-size: 1.2rem;
  background-color: g.$light-gray;
  border-radius: 6px;
  padding: 1rem;
}
