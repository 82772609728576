@charset 'UTF-8';

@use '../global/' as *;

// variable
//
$pagetop-active-class-name: '.pagetop-active';

.pagetop {
  background-color: $white;
  border-radius: 50%;
  color: $secondary;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 0.4rem;
  position: fixed;
  bottom: 1.5rem;
  right: 2rem;
  opacity: 0;
  text-align: center;
  text-decoration: none;
  visibility: hidden;
  transition: all $base-transition;
  width: 38px;
  height: 38px;
  z-index: $z-index-pagetop;
  // 矢印
  &:after {
    content: '';
    @include setSvgIcon($chevron-up, $primary, 2.4rem);
    margin-top: 0.2rem;
  }
  @media (hover: hover) {
    &:hover {
      background: $primary;
      &::after {
        @include setSvgIcon($chevron-up, $white, 2.4rem);
      }
    }
  }

  &#{$pagetop-active-class-name} {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  @include media(md) {
    width: 54px;
    height: 54px;
    bottom: 3.8rem;
    right: 3.8rem;
    &:after {
      @include setSvgIcon($chevron-up, $primary, 3rem);
      margin-top: 0.7rem;
    }
    @media (hover: hover) {
      &:hover {
        &::after {
          @include setSvgIcon($chevron-up, $white, 3rem);
        }
      }
    }
  }
}
