@charset 'UTF-8';

@use '../global/' as *;

// variable
$accordion-open-class-name: '.active';

.accordion-wrapper {
  width: 100%;
}

.accordion {
  border-radius: 6px;
  position: relative;
  transition: background-color $base-transition;
  & + .accordion {
    margin-top: 4px;
    border-top: 1px solid $border-color;
  }
}

.accordion-heading {
  width: 100%;
  margin-bottom: 0;
  padding: 16px 14px 16px 34px;
  position: relative;
  border-radius: 6px 6px 0 0;
  font-size: 1.5rem;
  font-weight: normal;
  color: $secondary;
  cursor: pointer;
  @include media(lg) {
    font-size: 1.8rem;
  }
  // arrow
  &::before,
  &::after {
    width: 2px;
    height: 14px;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: $secondary;
    transition: all $base-transition;
    content: '';
  }
  &::before {
    transform: translateY(-50%) rotate(90deg);
  }
  &#{$accordion-open-class-name} {
    &::after {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}

.accordion-contents {
  overflow: hidden;
  max-height: 0;
  transition: max-height $base-transition;
  border-radius: 0 0 6px 6px;
}

.accordion-contents-inner {
  padding: 0 1rem 3rem 3.4rem;
}
