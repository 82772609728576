@charset 'UTF-8';
@use './variables' as *;
@use './functions' as *;
@use 'sass:math';



// Media Queries
@mixin media($data, $loop: false )
{
  $key: map-get( $breakpoints, $data );
  @if( $data == 'xs' ){
    @if $loop != false {
      @content;
    } @else {
      $key: map-get( $breakpoints, sm ) - 1px;
      @media screen and ( max-width: $key ) {
        @content;
      }
    }

  } @else {
    @media screen and ( min-width: $key ) {
      @content;
    }
  }
}



// Container
@mixin makeContainer()
{
  margin: 0 auto;
  @each $key, $value in $breakpoints {
    @include media($key, true){
      padding: 0 map-get($base-side-padding, $key);
      max-width: get_max_width($key);
    }
  }
}



// Make grid
@mixin flexProperty($width)
{
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}



// Make grid items
@mixin makeGridItems($key, $num)
{
  .#{$key}-#{$num} {
    @include flexProperty( math.div(100%, math.div($grid-columns, $num)) );
    margin: 0;
  }
  .#{$key}-#{$num}-center {
    @include flexProperty( math.div(100%, math.div($grid-columns, $num)) );
    margin: 0 auto;
  }
  .#{$key}-offset-#{$num} {
    @include flexProperty( math.div(100%, math.div($grid-columns, $num)) );
    margin-left: ( math.div(100%, math.div($grid-columns, $num)) );
  }
  .#{$key}-col-#{$num} {
    @include flexProperty( math.div(100%, $num) );
    $pad: math.div(map-get($grid-gutters, $key), 2);
    margin: 0;
    padding: #{$pad};
  }
}



// Icon
@mixin setSvgIcon( $svg: $arrow-right, $color: $base, $size: 1rem )
{
  background-image: set-svg($svg, $color);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: $size;
  height: $size;
}



// ファイルアップロード
@mixin file-selector-button( $x: 1em, $y: 1em )
{
  background-color: $gray-100;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: $gray-dark;
  margin: ($x * -1) ($y * -1);
  margin-inline-end: $x;
  padding: $y $x;
  pointer-events: none;
}
