@charset 'UTF-8';

@use '../global/' as *;

.header-wrapper {
  background-color: $white;
  position: relative;
  @include media(lg) {
    padding: 0 2rem;
    background-color: transparent;
  }
}

.header-container {
  padding: 0.3rem 0 0.9rem 1rem;
  @include media(lg) {
    margin: 0 auto;
    padding: 2.4rem 3.4rem 2.4rem 5rem;
    background-color: $white;
    box-shadow: $base-box-shadow;
    border-radius: 0 0 1.2rem 1.2rem;
  }
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media(lg) {
  }
}

.header-logo {
  width: 40%;
  max-width: 138px;
  margin-top: 4px;
  @include media(lg) {
    max-width: 350px;
    margin-top: 0;
    img {
      width: 100%;
    }
  }
}

.header-menu {
  width: 100%;
  flex: 1;
  text-align: center;
  @include media(lg) {
    display: none;
  }
  &-list {
    display: flex;
    justify-content: flex-end;
    a,
    .burger-wrapper {
      font-size: 1rem;
      font-weight: bold;
      color: $secondary;
      line-height: 1;
      padding: 2.4px 3.3px;
    }
    a {
      display: block;
      .icon {
        font-size: 1.8rem;
        display: block;
        margin-bottom: 0.6rem;
      }
    }
  }
}
