@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

ul.caution-news-list {
  display: table;
  margin: 0;
  padding: 0;
  width: 100%;
}

li.caution-news-container {
  padding: 1.3em 0 1.1em;
  position: relative;
  transition: background-color g.$base-transition;

  @include sys.media(lg) {
    padding: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  // @media (hover: hover) {
  //   &:hover {
  //     background-color: g.$quinary;
  //     color: g.$base;
  //   }
  // }

  .news-anchor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 0 0.5rem 0;
    @include sys.media(lg) {
      padding: 1.25rem 0 1.25rem 1.25rem;
    }
  }

  .date {
    display: table-cell;
    font-family: g.$base-font-family;
    font-size: 0.93333em;
    padding: 0.5rem;
    white-space: nowrap;
    @include sys.media(lg) {
      font-size: 0.8125em;
      padding: 1.25rem 1.25rem 1.25rem 0.8em;
    }
  }

  .category {
    display: table-cell;
    padding: 0.5em;
    @include sys.media(lg) {
      padding: 1.25rem;
    }

    .category-label {
      background-color: g.$primary;
      border-radius: 2em;
      display: block;
      font-size: 0.866667em;
      padding: 0.25rem 0.5rem;
      text-align: center;
      white-space: nowrap;
      width: 76px;
      @include sys.media(lg) {
        padding: 0.25rem 1rem;
        width: 100%;
        min-width: 7em;
      }
      /*
      &.patient {
        background-color: sys.$secondary;
        color: sys.$white;
      }
      &.ordinary {
        background-color: sys.$primary;
        color: sys.$white;
      }
      &.participants {
        background-color: sys.$primary;
        color: sys.$white;
      }
      */
    }
  }
  .title {
    color: g.$quinary;
    display: block;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    width: 100%;
    position: relative;
    @include sys.media(lg) {
      display: table-cell;
      padding: 1.25rem 0 1.25rem 1.25rem;
    }
    a {
      color: g.$important;
    }
  }
}
