@charset 'UTF-8';
@use './global/' as *;



.loading-wrapper {
  background-color: $base;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity $base-transition, visibility $base-transition;
  z-index: $z-index-loading;
  &.disabled {
    opacity: 0;
    visibility: hidden;
  }
}

.loading-container {
  margin: 0 auto;
  width: 90%;
}

.loading {
  animation: rotate-loading 1.5s linear 0s infinite normal;
  border-radius: 100%;
  border: 2px solid transparent;
  border-color: transparent $white transparent $white;
  margin: 0 auto;
  position: relative;
  width: 100px;
  height: 100px;
  transform-origin: 50% 50%;
}

.loading-text {
  animation: loading-text-opacity 2s linear 0s infinite normal;
  color: $white;
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
}



@keyframes rotate-loading {
  0%  {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-text-opacity {
  0%  {
    opacity: 1
  }
  20% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
