@charset 'UTF-8';

@use '../global/' as *;

// breadlist-wrapper
//
.breadlist-wrapper {
}

// breadlist-container
//
.breadlist-container {
  @include makeContainer();
}

// breadlist
//
ol.breadlist {
  display: flex;
  align-items: center;
  font-size: 1rem;

  li {
    color: inherit;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + li::before {
      content: '>';
      padding-right: 0.8rem;
      padding-left: 0.8rem;
      color: rgba($color: $base, $alpha: 0.5);
    }

    a {
      color: rgba($color: $base, $alpha: 0.5);
      &::active,
      &::visited {
        color: inherit;
      }
    }

    &.current {
      color: $gray-dark;
      font-weight: 500;
    }
  }
}
