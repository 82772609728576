@charset 'UTF-8';
@use '../global/' as *;
@use 'sass:math';

/* common
====================================================*/
// iOSのフォーカス時のズームも無効にする
input[type='text'],
input[type='radio'],
input[type='checkbox'],
textarea {
  font-size: $form-font-size;
}

// input & textarea
.input {
  background-color: $white;
  border: 1px solid $border-color;
  border-radius: 6px;
  box-shadow: none;
  color: $gray-dark;
  font-size: 1em;
  line-height: 1;
  padding: $input-y-padding $input-x-padding;
  width: 100%;
  transition: border-color $base-transition, background $base-transition;

  // プレースホルダー
  &::placeholder {
    font-size: $placeholder-font-size;
    transition: color $base-transition;
  }
  &::-moz-placeholder {
    color: $gray-600;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $gray-600;
  }
  &::-webkit-input-placeholder {
    color: $gray-600;
  }
  &::-ms-expand {
    background: $white;
    border: 0;
  }

  // File input
  &[type='file'] {
    overflow: hidden;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &::file-selector-button {
    @include file-selector-button($input-x-padding, $input-y-padding);
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: $gray-200;
  }

  &::-webkit-file-upload-button {
    @include file-selector-button($input-x-padding, $input-y-padding);
  }
  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: $gray-200;
  }

  // フォーカス
  &:focus {
    background-color: $white;
    border-color: $base;
    box-shadow: 0 0 6px 1px rgba($base, 0.075);
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  // 入力済み
  &:not(:placeholder-shown) {
    // background-color: $gray-100;
    // border: $component-border-width solid $component-border-color;
  }

  // テキストエリア
  @at-root textarea#{&} {
    line-height: 1.6;
    height: auto;
    padding: 1em;
    resize: vertical;
    width: 100%;
    display: block;
  }

  & + & {
    margin-top: var(--form-grid-gutter);
  }

  // error
  &.error {
    border: $component-border-width solid $red;
  }

  &:read-only,
  &:read-only:focus {
    background-color: $gray-100;
    border: $component-border-width solid $component-border-color;
    box-shadow: none;
    color: $gray-600;
    // font-size: 0.75em;
    // @include media(md){
    //   font-size: 0.875em;
    // }
  }
}

// textarea
textarea {
  width: 100%;
  height: auto;
  padding: 1rem;
  resize: vertical;
}
// select
select {
  width: 100%;
  &::-moz-focus-inner {
    border: 0;
  }
  &.select {
    background-color: $white;
    background-image: set-svg($chevron-down, $secondary);
    background-position: right 1rem center;
    background-repeat: no-repeat;
    border: $component-border-width solid $component-border-color;
    border-radius: $component-border-radius;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: $form-font-size;
    outline: none;
    padding: $input-y-padding 3em $input-y-padding 1em;
    position: relative;
    transition: border-color $base-transition;
    &::-moz-focus-inner {
      border: 0;
    }
    // error
    &.error {
      border-color: $red;
    }
  }
}

// radio button & checkbox
input[type='checkbox'],
input[type='radio'] {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  margin: 0;
  outline: none;
}
.checkbox-group,
.radio-group {
  display: inline-block;
  // 横並び
  &.row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: (-$form-radio-checked-gutter-y);
    .checkbox-item,
    .radio-item {
      margin-right: 1.5rem;
      padding-top: $form-radio-checked-gutter-y;
    }
  }
}
.label-checkbox,
.label-radio {
  display: flex;
  padding: $radio-checked-gutter;

  label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    &::before {
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      margin-right: 0.6rem;
      width: 2rem;
      height: 2rem;
      transition: background $base-transition;
    }
  }
}
.label-checkbox {
  input[type='checkbox'] {
    + label {
      &::before {
        background-image: set-svg($checkbox, $gray-dark);
      }
    }
    &:checked + label {
      &::before {
        background-image: set-svg($checkbox-checked, $base);
      }
    }
  }
}
.label-radio {
  input[type='radio'] {
    + label {
      &::before {
        background-image: set-svg($radio, $gray-dark);
      }
    }
    &:checked + label {
      &::before {
        background-image: set-svg($radio-checked, $base);
      }
    }
  }
}

// readonly
.input-readonly {
  background: $white;
  box-shadow: none;
  color: $gray-dark;
  font-size: $form-font-size;
  font-weight: 700;
  line-height: 1;
  padding: 0.5em 0;
  width: 100%;
  // フォーカス
  &:focus {
    background: $white;
    border-color: $base;
    box-shadow: none;
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
}

.input-parent {
  display: flex;
  align-items: center;
  position: relative;
  & + & {
    margin-top: var(--form-grid-gutter);
  }
  .grid,
  .form-grid {
    flex: 1;
  }
}

// サブタイトルあり
.input-container {
  & + & {
    margin-top: 1rem;
  }
}

/* Layout
====================================================*/
$form-container-gutter: 2rem;

.form {
  margin-bottom: 2rem;
}

.form-container {
  background-color: $white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: $form-container-padding;
  width: 100%;

  & + &,
  & + .h-adr,
  .h-adr + & {
    margin-top: $form-container-gutter;
  }
}

.form-container-spacer {
  margin: $form-container-gutter 0;
}

.form-container-spacer-top {
  margin: $form-container-gutter 0 0;
}

.form-container-spacer-bottom {
  margin: 0 0 $form-container-gutter;
}

// フォームの項目名表示
.form-title {
  background: $form-title-background-color;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1.2rem;
  width: 100%;

  // 項目名
  .title {
    font-weight: bold;
    @include media(md) {
      flex: 0 0 19rem;
    }
  }

  // 必須と任意
  .optional,
  .required {
    font-weight: normal;
    line-height: 1;
    margin-left: 1rem;
    padding: 0.4rem 0.4rem calc(0.4rem - 1px);
  }
  // 任意
  .optional {
    color: $optional-color;
  }
  // 必須
  .required {
    color: $required-color;
  }
}

// フォーム入力表示
.form-value {
  background: $form-value-background-color;
  @include media(md) {
    flex: 1;
  }
}

// 各項目の間隔
.form-field {
  & + & {
    @each $key, $value in $breakpoints {
      @include media($key) {
        margin-top: map-get($form-field-gutters, $key);
      }
    }
  }
}

// verify
.form-verify-wrapper {
  background-color: $gray-100;
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity $base-transition, visibility $base-transition;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 999;

  &#{$verify-visible-class} {
    opacity: 1;
    visibility: visible;
  }
}

body#{$verify-visible-class} {
  overflow: hidden;
}

.form-verify-container {
  background-color: $white;
  box-shadow: $base-box-shadow;
  border-radius: 12px;
  margin: 1em auto;
  padding: 1em;
  width: min(92%, 720px);
  @include media(ld) {
    padding: 3em;
  }
}

h2.verify-heading {
  color: $base;
  font-size: 1.25em;
  margin-bottom: 2em;
  text-align: center;
}

.verify-contents {
  text-align: center;
  & + & {
    border-top: 1px solid $gray-200;
    margin-top: 1em;
    padding-top: 1em;
  }
}

.verify-title {
  color: $base;
  font-size: 0.875em;
  font-weight: 700;
  margin-bottom: 0.875em;
  padding-bottom: 0.5em;
  position: relative;
  &::before {
    content: '';
    background-color: $base;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2rem;
    height: 2px;
  }
}

.verify-value {
  margin-top: 0.75em;
}

.verify-btn-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 4rem auto 0;
  width: 200px;
  @include media(ld) {
    flex-direction: row;
    gap: 1rem;
    margin-top: 4rem;
    width: 490px;
  }
}

.return-column,
.exec-column {
  flex: 1;
  width: 100%;
  @include media(ld) {
    flex: 1;
    width: auto;
  }
}

.return-column {
  margin-top: 1rem;
  order: 1;
  @include media(ld) {
    margin-top: 0;
    order: 0;
  }
}

.exec-column {
  order: 0;
  @include media(ld) {
    order: 1;
  }
}

.btn-verify {
  background-color: $base;
  border: $btn-border-width solid transparent;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: $form-btn-font-size;
  font-weight: 500;
  line-height: inherit;
  text-align: center;
  appearance: none;
  text-decoration: none;
  transition: background-color $base-transition, color $base-transition;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0.8em 1em;

  // hover
  @media (hover: hover) {
    &:hover {
      background-color: lighten($base, 7.5%);
      color: $white;
    }
  }

  // Outline
  &.outline {
    background-color: $white;
    border: $btn-border-width solid $base;
    color: $base;
    // Outline hover
    @media (hover: hover) {
      &:hover {
        background-color: $base;
        color: $white;
      }
    }
  }

  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

/* grid
====================================================*/

.form-grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc(var(--form-grid-gutter) * -0.5);
  margin-right: calc(var(--form-grid-gutter) * -0.5);
  margin-left: calc(var(--form-grid-gutter) * -0.5);
  margin-bottom: calc(var(--form-grid-gutter) * -0.5);

  // 隣接
  & + & {
    margin-top: calc(var(--form-grid-gutter) * 0.5);
  }

  @each $key, $value in $breakpoints {
    @include media($key, true) {
      @for $i from 1 through $form-grid-columns {
        @include makeGridItems($key, $i);
      }
    }
  }
}

/* components
====================================================*/

// privacy
// プライバシーポリシーに同意する
.privacy-checkbox-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
.privacy-checkbox-container {
  display: inline-block;
  margin: 0 auto;
  //width: 100%;
  //max-width: $form-container-max-width;
}
.privacy-link {
  margin-top: 0.5em;
  text-align: center;
  a {
    color: $secondary;
    display: inline-flex;
    align-items: center;
    font-size: 0.875em;
    &::before {
      content: '';
      @include setSvgIcon($open-new-window, $secondary, 1.125em);
    }
  }
}
.label-privacy {
  display: flex; // checkbox に高さを与えるため
  margin: 0 auto;
  //height: 100%;
  [type='radio'],
  [type='checkbox'] {
    & + .privacy-container {
      background-color: $gray-100;
      border: 2px solid transparent;
      border-radius: 8px;
      cursor: pointer;
      color: $gray-500;
      display: flex;
      flex-direction: row;
      align-items: center;
      //height: 100%;
      padding: 1em;
      position: relative;
      transition: background-color $base-transition, border $base-transition;

      @media (hover: hover) {
        &:hover {
          background-color: $gray-200;
        }
      }
      &:before {
        content: '';
        background-image: set-svg($checkbox, $gray-500);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        margin-right: 0.5em;
        width: 1.5em;
        height: 1.5em;
        transition: background $base-transition;
      }
    }

    &:checked + .privacy-container {
      background-color: $gray-100;
      border: 2px solid $base;
      color: $gray-dark;
      &:before {
        background-image: set-svg($checkbox-checked, $base);
      }
    }
  }
  // Radio
  [type='radio'] {
    & + .privacy-container {
      &::before {
        background-image: set-svg($radio, $gray-dark);
      }
    }
    &:checked + .privacy-container {
      &::before {
        background-image: set-svg($radio-checked, $white);
      }
    }
  }
}

// display none
.display-none {
  display: none;
}

// border アニメーション
.border-animation {
  &::after {
    background: $border-animation-color;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $border-animation-thickness;
    z-index: 10;
    transform-origin: center;
    transform: scaleX(0);
    transition: all 0.3s ease;
  }
  #{$form-focus-in-class-name} &,
  #{$form-focus-fixed-class-name} & {
    &::after {
      transform: scaleX(1);
    }
  }
  &._textarea {
    &::after {
      bottom: 4px;
      @include media(lg) {
        bottom: 6px;
      }
    }
  }
}

// Placeholder
.placeholder-parent {
  position: relative;
}
.placeholder-item {
  color: $placeholder-color;
  font-size: inherit;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transform-origin: 0 50%;
  transition: 0.2s;

  #{$form-focus-in-class-name} &,
  #{$form-focus-fixed-class-name} & {
    color: $placeholder-after-color;
    font-weight: 700;
    top: -8px;
    transform: scale(0.8);
  }
}

// calendar
// カレンダーアイコンの表示
.icon-calendar {
  display: flex;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 3em;
  transform: translateY(-50%);
  &::before {
    content: '';
    background-image: set-svg($calendar, $gray-400);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
  }
  // Delete アイコンが無いとき
  &.no-delete {
    right: 1rem;
  }
}

// カレンダーの入力値を削除するボタン
.delete-calendar {
  background-image: set-svg($remove, $gray-600);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  width: 1.5em;
  height: 1.5em;
  transition: background-image $base-transition;
  @media (hover: hover) {
    &:hover {
      background-image: set-svg($remove, $gray-400);
    }
  }
}

.form-notes {
  font-size: 1rem;
  padding-left: 1em;
  text-indent: -1em;
  &::before {
    content: '※';
  }
}

.h-adr {
  display: block;
}

/* buttons
====================================================*/
// 確認ボタン要素の領域
.form-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.form-btn-container {
  display: flex;
  flex-direction: column;
  margin: $form-btn-gutter * -1;

  @include media(md) {
    flex-direction: row;
  }

  .btn-column {
    flex: 0 0 auto;
    padding: $form-btn-gutter;
    order: 1;
    &.sp-order-first {
      order: 0;
      @include media(md) {
        order: 1;
      }
    }
  }
}

.form-btn {
  min-width: 228px;
  background: $primary;
  border: $form-btn-border-width solid transparent;
  border-radius: $form-btn-border-radius;
  cursor: pointer;
  color: $white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $form-btn-font-size;
  font-weight: 700;
  line-height: $form-btn-height;
  height: calc($form-btn-height + $form-btn-border-width * 2);
  outline: none;
  padding: 0 $form-btn-x-padding;
  text-align: center;
  text-decoration: none;
  transition: all $base-transition;
  white-space: nowrap;
  // Hover
  @media (hover: hover) {
    &:hover {
      background: lighten($primary, 10%);
      color: $white;
    }
  }

  // キャンセルとか
  &.outline {
    background: $white;
    border: $form-btn-border-width solid $primary;
    color: $primary;
    @media (hover: hover) {
      &:hover {
        opacity: 0.6;
      }
    }
  }
  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

/* varidate
====================================================*/
// エラー表示用
.validate {
  display: none;
  line-height: 1;

  &.valid {
    display: inline-block;
  }

  .error {
    animation: fadeIn $base-transition;
    color: $danger;
    display: inline-block;
    font-size: 1.3rem;
    @include media(ld) {
      font-size: 1.4rem;
    }
  }
  // 下部
  &.lower {
    margin-top: 8px;
    margin-left: 0;
  }
}

/* modal
====================================================*/
$modal-open-class: '.form-confirm-open';

.form-confirm-wrapper {
  background: $gray-100;
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity $base-transition, visibility $base-transition;
  width: 100%;
  height: 100vh;
  z-index: 999;

  #{$modal-open-class} & {
    opacity: 1;
    visibility: visible;
  }
}

.form-confirm-container {
  background: $white;
  box-shadow: $base-box-shadow;
  border-radius: 12px;
  margin: 4% auto 0;
  padding: 1em;
  width: 92%;
  @include media(sm) {
    padding: 3em;
    max-width: 768px;
  }
}

.modal-window-body-inner {
  padding: 1.5em;
}

.modal-heading {
  display: block;
  margin-bottom: 2em;
  text-align: center;

  span {
    background: $base;
    border-radius: 2em;
    color: $white;
    display: inline-block;
    font-weight: 700;
    padding: 0.5em 1em;
    // 危険
    &.danger {
      background: $red;
    }
  }
}

h2.confirm-heading {
  color: $base;
  font-size: 1.25em;
  margin-bottom: 2em;
  text-align: center;
}

.confirm-form-container {
  display: block;
  @include media(sm) {
    display: flex;
  }

  & + & {
    margin-top: 2em;
  }
}

.confirm-form-title {
  border-bottom: 1px solid $gray-300;
  font-size: 0.875em;
  font-weight: 700;
  margin: 0 0 1em;
  padding: 0 0 4px;
  @include media(sm) {
    border-bottom: none;
    flex: 0 0 36%;
    margin: 0 1em 0 0;
    padding: 0;
    text-align: right;
  }
}

.confirm-form-value {
  @include media(sm) {
    flex: 1;
  }
}
