@charset 'UTF-8';

@use '../../var/' as global;

// Breakpoint
$breakpoints: (
  xs: map-get(global.$breakpoints, xs),
  sm: map-get(global.$breakpoints, sm),
  md: map-get(global.$breakpoints, md),
  lg: map-get(global.$breakpoints, lg),
  xl: map-get(global.$breakpoints, xl),
) !default;

// Color
$base: global.$base !default;
$primary: global.$primary !default;
$secondary: global.$secondary !default;
$primary: global.$primary !default;
$quaternary: global.$quaternary !default;
$quinary: global.$quinary !default;
$senary: global.$senary !default;
$septenary: global.$septenary !default;
$octonary: global.$octonary !default;
$novenary: global.$novenary !default;
$decenary: global.$decenary !default;

$red: global.$red !default;
$yellow: global.$yellow !default;
$orange: global.$orange !default;
$green: global.$green !default;
$blue: global.$blue !default;
$pink: global.$pink !default;
$purple: global.$purple !default;
$cyan: global.$cyan !default;

$gray-100: global.$gray-100 !default;
$gray-200: global.$gray-200 !default;
$gray-300: global.$gray-300 !default;
$gray-400: global.$gray-400 !default;
$gray-500: global.$gray-500 !default;
$gray-600: global.$gray-600 !default;
$gray-700: global.$gray-700 !default;
$gray-800: global.$gray-800 !default;
$gray-900: global.$gray-900 !default;
$gray-dark: global.$gray-dark !default;
$black: global.$black !default;
$white: global.$white !default;

$primary-50: global.$primary-50 !default;
$primary-100: global.$primary-100 !default;
$primary-200: global.$primary-200 !default;
$primary-300: global.$primary-300 !default;
$primary-400: global.$primary-400 !default;
$primary-500: global.$primary-500 !default;
$primary-600: global.$primary-600 !default;
$primary-700: global.$primary-700 !default;
$primary-800: global.$primary-800 !default;
$primary-900: global.$primary-900 !default;
$primary-on-high: global.$primary-on-high !default;
$primary-on-medium: global.$primary-on-medium !default;
$primary-on-disabled: global.$primary-on-disabled !default;

$secondary-50: global.$secondary-50 !default;
$secondary-100: global.$secondary-100 !default;
$secondary-200: global.$secondary-200 !default;
$secondary-300: global.$secondary-300 !default;
$secondary-400: global.$secondary-400 !default;
$secondary-500: global.$secondary-500 !default;
$secondary-600: global.$secondary-600 !default;
$secondary-700: global.$secondary-700 !default;
$secondary-800: global.$secondary-800 !default;
$secondary-900: global.$secondary-900 !default;
$secondary-on-high: global.$secondary-on-high !default;
$secondary-on-medium: global.$secondary-on-medium !default;
$secondary-on-disabled: global.$secondary-on-disabled !default;

$text: global.$base !default;
$base-border-color: global.$border-color !default;

// 休診・代診 Color
$closure-bg-color: #f2dcd7 !default;
$closure-title-bg-color: #ef5a39 !default;
$assist-bg-color: #eceef8 !default;
$assist-title-bg-color: #00aeba !default;

// 採用情報
$recruit-shin-color: $secondary !default; // 新卒
$recruit-chu-color: $quaternary !default; // 中途
$recruit-part-color: $senary !default; // パート

// Transition
$base-transition: global.$base-transition !default;

// Table
$table-border-color: $base-border-color;
$table-th-background-color: global.$table-th-background-color;
$table-td-background-color: global.$table-td-background-color;
$table-thead-th-background: global.$table-th-background-color;
$table-tbody-th-background: global.$primary;

// SVG icon
$arrow: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" fill=""><polygon points="12.9,4 10.7,6.1 15.1,10.4 3,10.4 3,13.6 15,13.6 10.7,17.9 12.9,20.1 21,12 "/></svg>') !default;

$ext: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" fill=""><path d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5l0,0v14c0,1.1,0.9,2,2,2l0,0h14c1.1,0,2-0.9,2-2v-7h-2V19zM14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/></svg>') !default;

$pdf: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M11.7,10.1c0-0.2-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3c0,0.7,0.1,1.3,0.4,2C11.6,11.4,11.7,10.8,11.7,10.1z M14.5,2v5h5L14.5,2z M7.6,18.5c0,0.2,0.1,0.3,0.3,0.4c0.1,0,0.2,0,0.3-0.1c0.2-0.3,0.6-0.8,1-1.6c-0.5,0.3-1,0.7-1.5,1.1C7.7,18.3,7.6,18.4,7.6,18.5z M13.2,7V2H6.4c-1,0-1.9,0.8-1.9,1.9v16.2c0,1,0.8,1.9,1.9,1.9l0,0h11.2c1,0,1.9-0.8,1.9-1.9l0,0V8.2h-5C13.8,8.3,13.3,7.7,13.2,7C13.3,7,13.2,7,13.2,7z M15.9,14.2c0.9,0,1.7,0.8,1.7,1.7s-0.7,1.7-1.6,1.7l0,0c-0.2,0-0.5,0-0.7-0.1c-0.8-0.3-1.5-0.6-2.1-1.1c-0.9,0.2-1.7,0.4-2.5,0.8c-0.4,0.8-0.9,1.5-1.4,2.3C9,19.9,8.5,20.1,8,20.2c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.4,0.1-0.8,0.4-1.2c0.7-0.7,1.5-1.3,2.5-1.7c0.5-0.9,0.8-1.8,1.2-2.7c-0.4-0.9-0.6-1.9-0.6-2.9c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6c0,0.8-0.1,1.6-0.3,2.4c0,0.1-0.1,0.3-0.1,0.4c0.3,0.6,0.7,1.1,1.1,1.5C14.5,14.3,15.2,14.2,15.9,14.2L15.9,14.2z M10.6,15.9c0.7-0.2,1.4-0.4,2.1-0.6c-0.5-0.4-0.8-0.9-1.2-1.5C11.2,14.5,10.9,15.2,10.6,15.9z M15.9,16.4c0.2,0,0.4-0.2,0.4-0.4c0-0.3-0.2-0.5-0.5-0.5l0,0h-0.2c0,0-0.7,0-1.6,0.2c0.5,0.3,1,0.5,1.5,0.7C15.8,16.4,15.8,16.4,15.9,16.4z"/></svg>') !default;

$calendar: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" fill=""><path d="M9,10v2H7v-2H9 M13,10v2h-2v-2H13 M17,10v2h-2v-2H17 M19,3c1.1,0,2,0.9,2,2v14c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h1V1h2v2h8V1h2v2H19 M19,19V8H5v11H19 M9,14v2H7v-2H9 M13,14v2h-2v-2H13 M17,14v2h-2v-2H17z"/></svg>') !default;

$open-new-window: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/></svg>') !default;

$caret-down: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><polygon points="2,7.6 3.2,6.4 12,15.2 20.8,6.4 22,7.6 12,17.6 "/></svg>') !default;
