@charset 'UTF-8';

@use '../global/' as *;

.copyright-wrapper {
  flex: none;
  padding: 5rem 0 0;
  position: relative;
  width: 100%;
  z-index: $z-index-footer;
}

.copyright-container {
  //@include makeContainer;
}

.copyright {
  color: $white;
  font-size: 1.2rem;
  text-align: center;
  @include media(lg) {
    text-align: left;
  }
}
