@charset 'UTF-8';
@use './global/' as sys;

.schedule-list-wrapper {
  position: relative;
}

.schedule-wrapper {
  position: relative;
  & + & {
    margin-top: 4rem;
  }
}

.schedule-section-name {
  border-top: 1px solid sys.$gray-300;
  border-bottom: 1px solid sys.$gray-300;
  font-weight: 700;
  margin-bottom: 1em;
  padding: 0.5em 1em;
}

.section-schedule-wrapper {
  position: relative;
}

table.section-schedule-table {
  text-align: center;
  width: 100%;
  min-width: max-content;
  table-layout: fixed;
  th,
  td {
    padding: 0.6em 1.2rem;
  }
  th {
    text-align: center;
    width: 20%;
  }
  thead {
    th,
    td {
      color: sys.$white;
      background-color: sys.$primary;
      text-align: center;
    }
  }

  tbody {
    th,
    td {
      border: 1px solid sys.$table-border-color;
      &:nth-of-type(n + 2) {
        @include sys.media(md) {
          width: 15%;
        }
      }
    }

    th {
      background-color: sys.$table-thead-th-background;
      font-weight: normal;
      padding: 1em;
    }

    td {
      background-color: sys.$table-td-background-color;
      overflow-wrap: break-word;
      padding: 1em;
    }
  }

  .time-zone_1,
  .time-zone_2,
  .time-zone_3 {
    background-color: sys.$table-tbody-th-background;
    font-weight: 700;
    font-size: 0.9375em;
    white-space: nowrap;
  }

  .reserve {
    background-color: sys.$gray-300;
    color: sys.$white;
    font-size: 0.75em;
    font-weight: 700;
    display: inline-block;
    line-height: 1.3;
    padding: 0.375em 0.5em;
    white-space: nowrap;
    display: inline;
    border-radius: 3px;
    &.type_1 {
      background-color: sys.$quaternary;
      display: none;
    }
    &.type_2 {
      background-color: sys.$secondary;
    }
    &.type_3 {
      background-color: sys.$white;
      border: 1px solid sys.$secondary;
      color: sys.$secondary;
    }
  }

  .doctor-container {
    white-space: normal;
    & + .doctor-container {
      margin-top: 1em;
    }
  }

  .doctor-name {
    font-weight: 700;
    margin-top: 0.25em;
    white-space: nowrap;
    // 男性医師名のカラー
    &.doctor-name-male {
      color: sys.$gray-dark;
    }
    // 女性医師名のカラー
    &.doctor-name-female {
      color: sys.$pink;
    }
  }

  .comment,
  .comment-sub {
    font-size: 0.75em;
    line-height: 1.3;
    margin-top: 0.25em;
  }
}

.update-at-schedule {
  font-size: 0.875em;
  margin-bottom: 0.5em;
  text-align: right;
}

.schedule-lower-comment {
  margin-top: 1em;
}

.lower-comment {
  font-size: 0.875em;
  line-height: 1.5;
  & + & {
    margin-top: 0.5em;
  }
}

.btn-section-link {
  background-color: sys.$primary;
  border-radius: 2em;
  border: 1px solid sys.$primary;
  color: sys.$white;
  display: inline-flex;
  align-items: center;
  padding: 0.6em 1em 0.6em 1.5em;
  text-decoration: none;
  &::after {
    content: '';
    @include sys.setSvgIcon(sys.$arrow, sys.$white, 1em);
    margin-left: 1em;
  }
  @media (hover: hover) {
    &:hover {
      background: sys.$white;
      color: sys.$primary;
      &::after {
        color: sys.$primary;
        @include sys.setSvgIcon(sys.$arrow, sys.$primary, 1em);
        right: 0.6em;
      }
    }
  }
}
