@charset 'UTF-8';
@use 'sass:math';
@use './global/' as sys;
@use '../var/' as g;

$recruit-border: solid 1px sys.$gray-400;

ul.recruit-list {
  width: 100%;
  li {
    background-color: transparent;
    border-left: $recruit-border;
    border-right: $recruit-border;
    display: flex;
    flex-direction: column;
    &:first-child {
      border-top: $recruit-border;
      border-radius: 6px 6px 0 0;
      overflow: hidden;
    }
    &:last-child {
      border-bottom: $recruit-border;
      border-radius: 0 0 6px 6px;
      overflow: hidden;
    }
    &:only-child {
      border-radius: 6px 6px 6px 6px;
      overflow: hidden;
    }
    @include sys.media(md) {
      flex-direction: row;
      &:nth-of-type(n + 2) {
        border-top: $recruit-border;
      }
    }
  }
}

.jobs-heading {
  background-color: sys.$quaternary;
  color: sys.$white;
  font-weight: 500;
  padding: 1.2em;
  text-align: left;
  @include sys.media(md) {
    border-right: $recruit-border;
    display: flex;
    align-items: center;
    flex: 0 0 30%;
  }
  .label-urgent {
    background: g.$white;
    color: g.$quinary;
    font-size: 1.2rem;
    padding: 0 0.3rem;
    border-radius: 3px;
    margin-left: 0.8rem;
  }
}

.recruit-btn-container {
  $gutter: 8px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  flex: 1;
  padding: 1.2em;

  .btn-column {
    flex: 0 0 32%;
  }
}

.btn-recruit {
  background-color: sys.$secondary;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  color: sys.$white;
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  line-height: 1.5;
  padding: 0.7rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all sys.$base-transition;
  //width: 100%;
  //height: 100%;

  span.kubun {
    display: block;
  }

  span.style {
    background-color: sys.$white;
    border-radius: 0.5rem;
    color: sys.$secondary;
    display: inline-block;
    line-height: 1;
    letter-spacing: 0;
    margin-top: 0.5rem;
    padding: 0.25rem 0.25rem;
    font-weight: 400;
    @include sys.media(md) {
      padding: 0.25rem 1rem;
    }
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
      color: sys.$white;
      transform: translate(0, 3px);
    }
  }

  // 募集していません
  &.disabled {
    background-color: #ddd !important;
    box-shadow: none;
    color: #666 !important;
    display: block;
    //padding: calc(.625em + .5rem) 1em;
    pointer-events: none;
    &::after {
      content: none;
    }
    span.style {
      color: #666 !important;
    }
  }

  // 新卒
  &.shin {
    background-color: sys.$recruit-shin-color;
    border-color: sys.$recruit-shin-color;
    color: sys.$white;
    @media (hover: hover) {
      &:hover {
        background-color: sys.$white;
        color: sys.$recruit-shin-color;
        &::after {
          background-image: sys.set-svg(sys.$arrow, sys.$recruit-shin-color);
        }
      }
    }
    span.style {
      color: sys.$recruit-shin-color;
    }
  }

  // 中途
  &.chu {
    background-color: sys.$recruit-chu-color;
    border-color: sys.$recruit-chu-color;
    color: sys.$white;
    @media (hover: hover) {
      &:hover {
        background-color: sys.$white;
        color: sys.$recruit-chu-color;
        &::after {
          background-image: sys.set-svg(sys.$arrow, sys.$recruit-chu-color);
        }
      }
    }
    span.style {
      color: sys.$recruit-chu-color;
    }
  }

  // パート
  &.part {
    background-color: sys.$recruit-part-color;
    border-color: sys.$recruit-part-color;
    color: sys.$white;
    @media (hover: hover) {
      &:hover {
        background-color: sys.$white;
        color: sys.$recruit-part-color;
        &::after {
          background-image: sys.set-svg(sys.$arrow, sys.$recruit-part-color);
        }
      }
    }
    span.style {
      color: sys.$recruit-part-color;
    }
  }

  // 外部サイトリンク
  &.external-site-url {
    position: relative;
    &::after {
      content: '';
      background-image: sys.set-svg(sys.$open-new-window, sys.$white);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0.5em;
      transform: translateY(-50%);
      width: 0.875em;
      height: 0.875em;
      @include sys.media(md) {
        right: 1em;
        width: 1em;
        height: 1em;
      }
    }
  }
}

// 募集要領

.recruit-detail-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;

  & + & {
    margin-top: 4rem;
  }

  a {
    color: g.$secondary;
    @media (hover: hover) {
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.recruit-heading {
  position: relative;
  padding: 0.8em 1em 1em 1.2em;
  border: 1px solid sys.$base;
  border-radius: 0 6px 0 6px;
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 8px;
    height: inherit;
    border-bottom: 2px solid sys.$white;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    right: 0;
    width: 8px;
    height: inherit;
    border-bottom: 2px solid sys.$white;
  }
  span {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background: sys.$primary;
    border-radius: 0 0 3px 0;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}

ul.recruit-detail-list {
  $border: 1px solid sys.$base-border-color;

  display: block;
  width: 100%;
  @include sys.media(md) {
    border-collapse: collapse;
    display: table;
  }

  li {
    border: $border;
    display: block;
    &:nth-of-type(n + 2) {
      border-top: none;
    }
    @include sys.media(md) {
      display: table-row;
    }

    .detail-head {
      background-color: sys.$gray-100;
      border-bottom: $border;
      display: block;
      padding: 0.5em 1em;
      text-align: center;
      @include sys.media(md) {
        border-right: $border;
        display: table-cell;
        vertical-align: middle;
        white-space: nowrap;
      }
    }

    .detail-content {
      display: block;
      padding: 1em;
      @include sys.media(md) {
        display: table-cell;
      }
    }
  }
}

.form-btn-wrapper {
  margin-top: 4rem;
}

.form-btn-container {
  display: flex;
  justify-content: center;
}

.form-btn-column {
  flex: 0 0 auto;
}

.btn-proceed-form {
  background-color: sys.$primary;
  border: 1px solid sys.$primary;
  border-radius: 2em;
  color: sys.$white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: g.$btn-font-size-normal;
  line-height: 1.4;
  text-decoration: none;
  padding: 1em 1em 1em 2em;
  &::after {
    content: '';
    @include sys.setSvgIcon(sys.$arrow, sys.$white, 1em);
    margin-left: 1em;
    transition: transform g.$base-transition;
  }
  @media (hover: hover) {
    &:hover {
      background-color: sys.$white;
      color: sys.$primary;
      &::after {
        background-image: sys.set-svg(sys.$arrow, sys.$primary);
        transform: translateX(6px);
      }
    }
  }
}
