@charset 'UTF-8';

@use '../global/' as *;

// Construct layout
.construct-container {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  width: 100%;
  min-height: 100vh;
  position: relative;
  letter-spacing: 0.06em;
  background: radial-gradient(circle at right 16px top 10px, $quaternary calc(310px / 2 - 1px), transparent calc(310px / 2)) no-repeat;
  @include media(lg) {
    background: radial-gradient(circle at right 200px top 17px, $quaternary calc(654px / 2 - 1px), transparent calc(654px / 2)) no-repeat;
  }
  &#{$burger-open-class} {
    overflow: hidden;
  }
}

.construct-header {
  background-color: transparent;
  flex: none;
  width: 100%;
  box-shadow: $base-box-shadow;
  z-index: $z-index-header;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: 2;
  @include media(lg) {
    box-shadow: none;
    overflow: visible;
  }
}

.construct-nav {
  background-color: transparent;
  flex: none;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform $base-transition;
  width: 85%;
  max-width: 305px;
  height: 100vh;
  z-index: $z-index-nav;
  // OPEN
  #{$burger-open-class} & {
    box-shadow: $base-box-shadow;
    transform: translateX(0);
  }
  @include media(lg) {
    width: 100%;
    max-width: none;
    height: auto;
    box-shadow: none;
    position: static;
    top: 0;
    transform: translateX(0);
    flex: 1;
  }
}

.construct-overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: opacity $base-transition;
  #{$burger-open-class} & {
    height: 100vh;
    opacity: 1;
    background-color: rgba($color: $black, $alpha: 0.45);
  }
}

.construct-main {
  background-color: transparent;
  flex: 1 0 auto;
  width: 100%;
  padding: 2.7rem 0 10.36rem;
  z-index: $z-index-main;
  @include media(lg) {
    padding: 4.9rem 0 16.8rem;
  }
  &.top {
    padding: 0;
    @include media(lg) {
      margin-top: -104px;
    }
  }
}

// Container
.container {
  padding-top: 7.3rem;
  @include media(lg) {
    padding-top: 8.1rem;
  }

  &.page {
    @include media(lg) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      @include makeContainer;
    }
  }
}

.contents {
  @include media(lg) {
    width: calc(100%);
  }
  &-fully {
    @include media(lg) {
      width: 100%;
    }
  }
}

// section
.section {
  & + & {
    @each $key, $value in $breakpoints {
      @include media($key, true) {
        margin-top: map-get($section-block-gutters, $key);
      }
    }
  }
}
