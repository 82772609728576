@charset 'UTF-8';

@use './color' as *;

// フォントサイズ
$form-font-size: 16px;

// Form Container の max-width
$form-container-max-width: 768px !default;

// Form Container の padding
$form-container-padding: 0 !default;

// Form Container のボーダー
$form-container-border: 1px solid $border-color !default;

// Form Container 同士のマージン
$form-container-gutter: 2.8rem;

// タイトルのバックグラウンドカラー
$title-background-color: $white !default;

// 入力のバックグラウンドカラー
$value-background-color: $white !default;

// 任意と必須
$optional-color: #339966 !default;
// $optional-background-color: #339966 !default;
$required-color: #d50000 !default;
// $required-background-color: #D50000 !default;

// placeholder
$placeholder-color: $gray-600 !default; // フォントカラー
$placeholder-font-size: 1em !default; // フォントサイズ

// input の padding
$input-x-padding: 1em;
$input-y-padding: 1.2em;

// Component のボーダー
$component-border-color: $gray-400 !default;
$component-border-width: 1px !default;
$component-border-radius: 8px !default;

// form-field 同士の余白
$form-field-gutters: (
  xs: 2rem,
  sm: 2rem,
  md: 2rem,
  lg: 2rem,
  xl: 2rem,
) !default;

// form-grid のカラム数
$form-grid-columns: 12 !default;

// グリッド要素同士の空白
$form-grid-gutters: (
  xs: 4px,
  sm: 4px,
  md: 4px,
  lg: 4px,
  xl: 4px,
) !default;

// フォームで使用するボタン
$form-btn-border: 1px;
$form-btn-border-radius: 2em;
$form-btn-font-size: 14px;
$form-btn-height: 44px;
$form-btn-padding: 16px;
$form-btn-gutter: 8px;

// border-form
//
// タイトルのバックグラウンドカラー
$form-title-background-color: $white;

// 項目のバックグラウンドカラー
$form-value-background-color: $white;

// コンテナのボーダー
$base-form-border: 1px solid $gray-200;

// コンテナの背景色
$base-form-background-color: $white;

// チェックボックス & ラジオボタン
$form-radio-checked-color: $base;
$form-radio-border-color: rgba($black, 0.54);
$form-radio-size: 18px;
$form-radio-checked-size: 10px;
$form-radio-ripple-size: 12px;

// チェックボックス & ラジオボタンの上下のマージン
$form-radio-checked-gutter-y: 10px;

// ボーダーの太さ
$border-animation-thickness: 1px;

// ボーダーの色
$form-border-color: $gray-200;

// ボーダーのアニメーションで変化するボーダー色
$border-animation-color: $base;

// form 要素をフォーカスしたときに付与するクラス名
$form-focus-in-class-name: '.focus-in';
$form-focus-fixed-class-name: '.focus-fixed';

// プレースホルダーの色
$placeholder-color: #ced4da;

// プレースホルダーの変化する色
$placeholder-after-color: #339966;

// エラーの色
$form-error-color: $red;
$form-error-background-color: #ffdfd8;

// 項目同士の余白
$form-field-gutter-xs: 2em !default;
$form-field-gutter-sm: 2em !default;
$form-field-gutter-md: 2em !default;
$form-field-gutter-lg: 2em !default;
$form-field-gutter-xl: 2em !default;

// form-grid 内のカラム数
$form-grid-columns: 12 !default;

// グリッド要素同士の空白
$form-grid-gutter: 8px !default;

// チェックボックス & ラジオボタンのマージン
$radio-checked-gutter: 0.5rem;

// 確認画面を表示するクラス名
$verify-visible-class: '.form-verify-visible';

// グリッド要素同士の空白
$form-grid-gutter-xs: 8px !default;
$form-grid-gutter-sm: 8px !default;
$form-grid-gutter-md: 12px !default;
$form-grid-gutter-lg: 12px !default;
$form-grid-gutter-xl: 12px !default;

// フォームボタン
$form-btn-border-width: 1px;
$form-btn-border-radius: 2em;
$form-btn-font-size: 14px;
$form-btn-height: 52px;
$form-btn-x-padding: 32px;
$form-btn-gutter: 8px;
