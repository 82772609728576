@charset 'UTF-8';
@use './global/' as *;

a {
  transition: all $base-transition;
  word-wrap: break-word;
  &:not([class]) {
    @media (hover: hover) {
      &:hover {
        opacity: 0.5;
      }
    }
  }
  &.link {
    @media (hover: hover) {
      &:hover {
        opacity: 0.5;
      }
    }
  }
  &.inline {
    color: $primary;
    text-decoration: underline;
    @media (hover: hover) {
      &:hover {
        opacity: 0.5;
        text-decoration: none;
      }
    }
  }
  &.underline {
    text-decoration: underline;
    @include media(lg) {
      @media (hover: hover) {
        &:hover {
          opacity: 0.5;
          text-decoration: none;
        }
      }
    }
  }
}

hr {
  border-color: $border-color;
}

//モバイル非表示
.pc {
  display: none !important;
  @include media(lg) {
    display: block !important;
  }
}

//PC非表示
.md {
  @include media(lg) {
    display: none !important;
  }
}

.txt {
  &-tel {
    margin-top: 1rem;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1;
    span {
      font-size: 1.6rem;
      padding-right: 0.5rem;
    }
  }
}

// コンテンツ
.content {
  &-title {
    margin-bottom: 3.8rem;
    font-size: 2.4rem;
    line-height: 1;
    letter-spacing: 0.05em;
    @include media(lg) {
      font-size: 3.8rem;
    }
    &--en {
      margin-bottom: 2.8rem;
      color: $secondary;
      font-size: 1.4rem;
      font-family: $base-font-family-en-raleway;
      display: block;
      letter-spacing: 0.1em;
      @include media(lg) {
        font-size: 2rem;
        margin-bottom: 5rem;
      }
    }
  }

  &-inner {
    @include makeContainer;
  }
}

// ページタイトル
.page-title {
  &::first-line {
    line-height: 1;
  }
  .category {
    font-size: 1.2rem;
    font-weight: bold;
    font-family: $base-font-family-en-raleway;
    color: $secondary;
    @include media(lg) {
      font-size: 1.8rem;
    }
  }
  .title {
    margin-top: 2rem;
    line-height: normal;
    @include media(lg) {
      margin-top: 2.2rem;
    }
  }
}

// Photo Layout
.common-photo {
  .photo {
    &:first-child {
      margin-bottom: 2.8rem;
    }
    .photo-inner {
      margin-bottom: 1rem;
      @include media(md) {
        text-align: center;
      }
    }
    .photo-note {
      font-size: 1.2rem;
      @include media(md) {
        font-size: 1.4rem;
        text-align: left;
      }
    }
    img {
      width: 100%;
      border-radius: 6px;
      @include media(md) {
        width: auto;
      }
    }
  }
  .text {
    &:first-child {
      margin-bottom: 2.8rem;
    }
  }

  &.photo-center {
    .photo {
      @include media(md) {
        margin: 0 auto 23px;
        width: fit-content;
      }
    }
  }
  &.photo-right,
  &.photo-left {
    @include media(md) {
      display: flex;
      justify-content: space-between;
    }
    .photo,
    .text {
      @include media(md) {
        width: calc(50% - 20px);
      }
    }
    .photo {
      img {
        @include media(md) {
          width: 100%;
        }
      }
    }
  }
  &.photo-right {
  }
  &.photo-left {
  }
}

// Box
.border-box {
  border: 1px solid $border-color;
  &--pink {
    border-color: $primary;
  }
}

.color-box {
  background-color: $gray-100;
  border-radius: 6px;
}

.border-box,
.color-box {
  padding: 2rem;
  border-radius: 6px;
  &--contact {
    background-color: $light-green;
    border: 1px solid #e2f5f5;
  }
  @include media(lg) {
    padding: 2.8rem;
  }
}

.text-wrap {
  margin: 2.2rem auto 2.4rem;
  padding: 2.7rem 2.7rem 2.5rem;
  p:last-child {
    margin-bottom: 0;
  }
  &--contact {
    padding: 8%;
  }
  .grid > div:not(:last-child) {
    margin-bottom: 1.2rem;
  }
  @include media(lg) {
    padding: 3.8rem 4rem 4.2rem;
    &--contact {
      padding: 4rem;
    }
    .grid > div:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.text-loop {
  width: 100%;
  overflow: hidden;
  &-wrap {
    display: flex;
    width: 100%;
    overflow: hidden;
    @include media(lg) {
      margin: 21.2rem auto 0;
    }
  }
  &__item {
    flex: 0 0 auto;
    white-space: nowrap;
    color: $senary;
    font-size: 8rem;
    font-family: $base-font-family-en-raleway;
    letter-spacing: 0.06em;
    line-height: 2.3;
    overflow: hidden;
    padding: 0 1em 0 0;
    @include media(lg) {
      font-size: 13rem;
      line-height: 1.2;
    }
    &:nth-child(odd) {
      animation: loop 50s -25s linear infinite;
    }
    &:nth-child(even) {
      animation: loop2 50s linear infinite;
    }
  }
}

@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}

// Maker
.marker {
  background-image: linear-gradient(90deg, $yellow 90%, transparent);
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: auto 0.75em;
  padding: 0 0.25em;
}

.marker-animation {
  padding: 0 0.25em;
  &.is-visible {
    background-image: linear-gradient(90deg, $yellow 90%, transparent);
    background-repeat: no-repeat;
    background-size: 200% 0.75em;
    /*2つ目の値でマーカーの太さを変える*/
    background-position: 0% bottom;
    animation: marker 3s ease 0s;
    /*マーカーを引く速度*/
  }
}

@keyframes marker {
  0% {
    background-position: right -100% bottom;
  }
  100% {
    background-position: 0% bottom;
  }
}

// 注釈
.indent {
  padding-left: 1em;
  text-indent: -1em;
}

// カード
.card {
  &__link {
    padding: 1.8rem 1.8rem 0.8rem;
    display: block;
    border-radius: 6px;
    border: 1px solid $border-color;
    @media (hover: hover) {
      &:hover {
        background-color: $light-green;
      }
    }
    &.max-height {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card__content {
        flex: 1;
      }
    }
  }
  &__photo {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 5px 5px 0 0;
    }
  }
  &__more {
    margin-top: 2rem;
    border-top: 1px solid $border-color;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: block;
    position: relative;
    &::after {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: 1.8rem;
      right: 0;
      content: '';
      background: set-svg($arrow-right, $white) no-repeat center / 1em $primary;
    }
  }
  &--image {
    .card__link {
      padding: 0;
    }
    .card__content {
      padding: 1rem 1.8rem;
    }
    .card__more {
      margin-top: 0;
      padding-left: 1.8rem;
      padding-right: 1.8rem;
      &::after {
        right: 1.8rem;
      }
    }
  }
}

// 緊急のお知らせ
.urgent-notice {
  padding: 2.8rem 0;
  background-color: $light-red;
  z-index: 1;
  @include media(lg) {
    padding: 3rem 0 2.4rem;
  }
  &-inner {
    position: relative;
    @include makeContainer;
    @include media(lg) {
      display: flex;
    }
  }

  &__title {
    color: $danger;
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 2.6rem;
    @include media(lg) {
      width: 30%;
      max-width: 200px;
      margin-bottom: 0;
    }
  }
  &__content {
    height: auto;
    max-height: 20px;
    overflow: hidden;
    transition: max-height 0.3s;
    @include media(lg) {
      max-height: 30px;
      padding-right: 12rem;
    }
    &.open {
      & + .urgent-notice__switch {
        .urgent-icon {
          &::before {
            transform: rotate(0);
          }
        }
      }
    }
  }

  &__list {
    line-height: 1;
    li {
      &:not(:last-child) {
        margin-bottom: 2.4rem;
      }
      a {
        text-decoration: underline;
      }
    }
  }

  &__switch {
    font-size: 1.6rem;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 2.2rem;
    letter-spacing: 0.05em;
    @include media(md) {
      right: 2rem;
    }
  }
  .urgent-icon {
    display: inline-block;
    vertical-align: middle;
    color: $base;
    line-height: 1;
    width: 12px;
    height: 1px;
    background: #707070;
    position: relative;
    margin-right: 0.8rem;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      transform: rotate(90deg);
      transition: transform 0.3s;
    }
  }
}

// アンカーリンク
.contents-list {
  margin-bottom: 40px;
  padding: 2em 2em 1.8em;
  border-radius: 6px;
  background-color: $light-gray;
  a {
    color: $secondary;
  }
  @include media(lg) {
    margin: 40px 0 40px;
    &:first-child {
      margin-top: 0;
    }
  }
  @include media(sm) {
    .underline {
      text-decoration: underline !important;
    }
  }
  .title {
    margin-bottom: 15px;
    line-height: 1.3;
  }
}

// 診療時間
.consultation-time {
  width: 100%;
  padding: 2rem 2.2rem 2.3rem;
  color: $secondary;
  background-color: $white;
  box-shadow: 0 3px 12px rgba($color: $primary, $alpha: 0.16);
  @include media(lg) {
    max-width: 405px;
  }
  &__table {
    width: 100%;
    thead {
      th,
      td {
        padding-top: 0;
      }
    }
    tr {
      border-bottom: 1px solid $secondary;
    }
    th,
    td {
      font-weight: normal;
      padding: 0.9rem 0;
    }
    th {
      text-align: left;
      width: 7.7em;
    }
    td {
      text-align: right;
    }
    &:last-child {
      margin-bottom: 1.6rem;
    }
  }
  &__closed {
    margin-top: 1.6rem;
  }
  &--footer {
    padding: 0;
    color: $white;
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 2rem;
    @include media(lg) {
      width: 405px;
      margin-top: 4px;
    }
    tr {
      border-bottom-color: $white;
    }
    th,
    td {
      @include media(lg) {
        padding: 1.2rem 0;
      }
    }
  }
}
