@charset 'UTF-8';

@use '../global/' as *;

// variable
$pagination-active-class: '.current';

.pagination-wrapper {
  width: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 11px;
  text-align: center;

  a,
  #{$pagination-active-class},
  span {
    border-radius: 3px;
    display: block;
    padding: 6px 8px 5px;
  }

  a {
    background-color: $white;
    color: $gray-dark;
    transition: background $base-transition;
    &:hover {
      background: $gray-100;
    }
  }
  #{$pagination-active-class} {
    background-color: $gray-200;
    font-weight: 700;
  }

  span {
    color: $gray-600;
  }

  li {
    margin: 0 2px;
  }
}
