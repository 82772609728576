@charset 'UTF-8';

@use '../global/' as *;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  line-height: $heading-line-height;
  margin-top: 0;
  text-rendering: optimizeLegibility;
  letter-spacing: $base-letter-spacing;
  &::first-line {
    line-height: 1;
  }
}

h1 {
  font-size: $h1-font-size;
  margin-bottom: $h1-margin-bottom;
  &:not(:first-child) {
    margin-top: $h1-margin-top;
  }
  @include media(lg) {
    font-size: 4.6rem;
    margin-bottom: 9.7rem;
  }
}

h2 {
  font-size: $h2-font-size;
  margin-bottom: $h2-margin-bottom;
  &:not(:first-child) {
    margin-top: $h2-margin-top;
  }
  @include media(lg) {
    font-size: 3.6rem;
    margin-bottom: 7.95rem;
  }
}

h3 {
  font-size: $h3-font-size;
  margin-bottom: $h3-margin-bottom;
  &:not(:first-child) {
    margin-top: $h3-margin-top;
  }
  @include media(lg) {
    font-size: 3rem;
    margin-bottom: 5.6rem;
  }
}

h4 {
  font-size: $h4-font-size;
  margin-bottom: $h4-margin-bottom;
  &:not(:first-child) {
    margin-top: $h4-margin-top;
  }
  @include media(lg) {
    font-size: 2.4rem;
    margin-bottom: 3.7rem;
  }
}

h5 {
  font-size: $h5-font-size;
  margin-bottom: $h5-margin-bottom;
  &:not(:first-child) {
    margin-top: $h5-margin-top;
  }
}

h6 {
  font-size: $h6-font-size;
  margin-bottom: $h6-margin-bottom;
  &:not(:first-child) {
    margin-top: $h6-margin-top;
  }
}

// heading
h2.heading {
  color: $secondary;
  padding-bottom: 2.4rem;
  position: relative;
  @include media(lg) {
    padding-bottom: 3.55rem;
  }
  &:after {
    content: '';
    width: 38px;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $secondary;
  }
}
h3.heading {
  padding-bottom: 2.2rem;
  border-bottom: 4px double $border-color;
  @include media(lg) {
    padding-bottom: 3.4rem;
  }
}

h4.heading {
  color: $secondary;
}

// Paragraph
.lead {
  font-size: inherit;
  letter-spacing: $lead-letter-spacing;
  line-height: $lead-line-height;
  text-rendering: optimizeLegibility;
  // .lead spacer
  & + & {
    margin-top: $lead-top-spacer;
  }
}

// フォントファミリーを日本語に限定する（※）
.mbf {
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Original Yu Gothic', 'Yu Gothic', sans-serif !important;
}

// code
pre {
  background-color: $gray-100;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
  code {
    background-color: transparent;
    color: $gray-700;
    font-family: 'SourceHanCodeJP-Regular', monospace;
    font-size: 0.8em;
    font-weight: bold;
    vertical-align: initial;
  }
}
