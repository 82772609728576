@charset 'UTF-8';
@use './global/' as sys;



.preview {
  background-color: #eef2f6;
  padding-bottom: 6rem;
}

.preview-header {
  background-color: sys.$white;
  margin-bottom: 6rem;
  padding: 1rem 0;
  text-align: center;
}

.preview-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-headline {
  font-size: 2rem;
  font-weight: 700;
}

.preview-block {
  background-color: sys.$white;
  border-radius: 8px;
  padding: 2rem;
  & + & {
    margin-top: 4rem;
  }
}

.preview-headline {
  border-bottom: 1px solid sys.$gray-300;
  color: sys.$gray-800;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1em;
}
