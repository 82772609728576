@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.news-container {
  margin: 2.1rem 0;
  position: relative;
  transition: background-color g.$base-transition;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include sys.media(lg) {
    margin: 1.5rem 0;
    padding: 0;
    flex-wrap: nowrap;
  }

  @media (hover: hover) {
    &:hover {
      background-color: g.$light-gray;
      color: g.$base;
    }
  }

  .news-body {
    width: 100%;
    padding: 1em 0;
  }

  .news-anchor {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 制限したい行数 */
    overflow: hidden;
    @include sys.media(lg) {
      -webkit-line-clamp: 1; /* 制限したい行数 */
    }
  }

  .date {
    padding: 0 0.2em;
    color: g.$base;
    font-family: g.$base-font-family;
    font-size: 1.4rem;
    white-space: nowrap;
    line-height: 1;
    letter-spacing: 0.02em;
  }

  .category {
    padding: 0.5em 2.2rem;
    @include sys.media(lg) {
      padding: 0.5em 3rem;
    }
    .category-label {
      border-radius: 6px;
      color: g.$base;
      display: block;
      font-size: 1.2rem;
      line-height: 1;
      padding: 0.7rem 0.6rem;
      text-align: center;
      white-space: nowrap;
      width: 100%;
      border: 1px solid g.$base;
      /*
      &.patient {
        background-color: sys.$secondary;
        color: sys.$white;
      }
      &.ordinary {
        background-color: sys.$primary;
        color: sys.$white;
      }
      &.participants {
        background-color: sys.$primary;
        color: sys.$white;
      }
      */
    }
  }
  .title {
    color: g.$base;
    display: block;
    padding: 0.8rem 0 0;
    width: 100%;
    position: relative;
    @include sys.media(lg) {
      padding: 1.25rem 4rem 1.25rem 0;
    }
  }
}

.news-post-date {
  display: flex;
  align-items: center;
  position: relative;
  color: sys.$gray-600;
  font-weight: 700;
  font-size: 0.875em;
  margin-bottom: 2em;
  &::before {
    content: '';
    background-image: sys.set-svg(sys.$calendar, sys.$gray-600);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    min-width: 1.25rem;
    position: relative;
    margin-right: 0.5rem;
    transition: opacity sys.$base-transition;
  }
}

.pagination-container {
  margin-top: 2.8rem;
}
