@charset 'UTF-8';

@use "../global/" as *;

.footer-wrapper {
  color: $white;
  padding: 5.2rem 0 5.3rem;
  background: url("/images/common/bg_footer_sp.png") no-repeat 0 0 / cover
    #f0841a;
  position: relative;
  @include media(lg) {
    padding: 8.5rem 0 6rem;
    background: url("/images/common/bg_footer.png") no-repeat 0 0 / cover
      #f0841a;
  }
}

.footer-container {
  @include makeContainer;
  @include media(lg) {
    padding-left: 12.4rem;
    padding-right: 12.4rem;
  }
}

.footer-inner {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    flex-flow: column-reverse wrap;
    @include media(lg) {
      flex-flow: row wrap;
      align-items: flex-start;
      margin-top: 4.8rem;
    }
  }
  @include media(lg) {
    flex-flow: row wrap;
  }
}

.footer-inner-left {
  width: 100%;
  @include media(lg) {
    width: auto;
  }
}

.footer-inner-right {
  width: 100%;
  @include media(lg) {
    width: auto;
  }
}

.footer-logo {
  margin-bottom: 4.2rem;
  text-align: center;
  @include media(lg) {
    text-align: left;
    margin-bottom: 0;
  }
  a {
    display: inline-block;
  }
  img {
    @include media(lg) {
      width: 310px;
    }
  }
}

.footer-nav {
  &.list-row {
    @include media(lg) {
      font-size: 1.5rem;
    }
    li {
      &:not(:last-child) {
        @include media(lg) {
          margin-right: 3rem;
        }
      }
    }
    a {
      color: $white;
    }
  }
}

.footer-address {
  text-align: center;
  font-size: 1.4rem;
  @include media(lg) {
    text-align: left;
  }
  &__tel {
    margin-top: 1rem;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1;
    span {
      font-size: 1.6rem;
      padding-right: 0.5rem;
    }
    a {
      color: $white;
    }
  }
}
