@charset 'UTF-8';

@use '../global/' as *;

.table {
  width: 100%;
  border-collapse: collapse;
  thead {
    th {
      color: $white;
      background-color: $primary;
    }
  }
  th,
  td {
    padding: 1.2em;
    font-weight: normal;
    text-align: left;
    border: 1px solid $table-border-color;
  }
  th {
    color: $secondary;
    background-color: $table-th-background-color;
  }
  td {
    background-color: $table-td-background-color;
    overflow-wrap: break-word;
  }
}

.table-base {
  border-bottom: 1px solid $table-border-color;
  th,
  td {
    display: block;
    @include media(lg) {
      display: table-cell;
    }
  }
  th {
    @include media(lg) {
      width: 30%;
    }
  }
  td {
    border-top: none;
    border-bottom: none;
    @include media(lg) {
      border: 1px solid $table-border-color;
    }
  }
}

.th-block {
  border-bottom: 1px solid $table-border-color;
  @include media(md) {
    border: none;
  }
  th,
  td {
    display: block;
    @include media(md) {
      display: table-cell;
    }
  }
  th {
    border-bottom: none;
    width: 100%;
    @include media(md) {
      border-bottom: 1px solid $table-border-color;
      width: 15%;
    }
  }
  td {
    border-bottom: none;
    @include media(md) {
      border-top: 1px solid $table-border-color;
      border-bottom: 1px solid $table-border-color;
    }
  }
}

// スクロールを出すための wrapper
.table-scroll-wrapper {
  width: 100vw;
  max-width: 100%;
  position: relative;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(md) {
    width: 100%;
    white-space: normal;
  }
}

.table-responsive {
  border-collapse: collapse;
  width: 100%;
  border: none;
  & > thead {
    display: none;
  }
  & > tbody {
    & > tr {
      &:last-child {
        @include media(md) {
          border-bottom: none;
        }
      }
      & > th,
      & > td {
        width: 100%;
        display: block;
        border: solid 1px $table-border-color;
        @include media(md) {
          display: table-cell;
          border-bottom: 1px solid $table-border-color;
        }
      }
      & > th {
        @include media(md) {
          width: 30%;
        }
      }
      & > td {
        margin-bottom: 2rem;
        @include media(md) {
          margin-bottom: 0;
        }
      }
    }
  }
}

//th_中央寄せ
.table_thcenter {
  th {
    text-align: center;
  }
}

//td_中央寄せ
.table_tdcenter {
  td {
    text-align: center;
  }
}

.table-layout-fixed {
  width: max-content;
  max-width: 100%;
  table-layout: fixed;
}
