@charset 'UTF-8';

@use '../global/' as *;

.btn {
  background-color: $base;
  border: $btn-border-width solid transparent;
  color: $white;
  cursor: pointer;
  display: inline-block;
  // font-size: $btn-font-size-normal;
  font-weight: 500;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: opacity $base-transition, color $base-transition, background-color $base-transition;
  vertical-align: middle;
  white-space: nowrap;
  padding: $btn-y-padding-normal $btn-x-padding-normal;
  line-height: normal;
  border-radius: 30px;

  // Sizes
  @each $name, $value in $btn-font-sizes {
    &.#{$name} {
      font-size: $value;
      padding: map-get($btn-y-paddings, $name) map-get($btn-x-paddings, $name);
    }
  }

  // hover
  @media (hover: hover) {
    &:hover {
      opacity: 1;
      background-color: lighten($base, 7.5%);
      color: $white;
    }
  }

  // Colors variation
  @each $name, $value in $btn-background-colors {
    &.#{$name} {
      background-color: $value;
      color: map-get($btn-colors, $name);
      @media (hover: hover) {
        &:hover {
          background: map-get($btn-hover-background-colors, $name);
          color: map-get($btn-hover-colors, $name);
        }
      }
    }
  }

  // Base
  &.base {
    min-width: 224px;
    @include media(md) {
      min-width: 228px;
    }
  }

  // Outline
  &.outline {
    background-color: transparent;
    border: $btn-border-width solid $base;
    color: $base;
    transition: all $base-transition;
    // Outline hover
    @media (hover: hover) {
      &:hover {
        background-color: $base;
        color: $white;
      }
    }
    // Outline colors
    @each $name, $value in $btn-background-colors {
      &.#{$name} {
        border: $btn-border-width solid $value;
        color: map-get($all-colors, $name);
        @media (hover: hover) {
          &:hover {
            opacity: 1;
            background-color: map-get($btn-hover-background-colors, $name);
            color: map-get($btn-hover-colors, $name);
          }
        }
      }
    }
  }

  // Icon
  span {
    display: inline-flex;
    align-items: center;
    @each $name, $icon in $svg-icon-types {
      &.#{$name} {
        &::after {
          content: '';
          @include setSvgIcon($icon, $white, 1rem);
          margin-left: 1rem;
        }
        &.left {
          &::after {
            content: none;
          }
          &::before {
            content: '';
            @include setSvgIcon($icon, $white, 1rem);
            margin-right: 1rem;
          }
        }
      }
      // icon color
      @each $key, $color in $all-colors {
        &.ic-#{$key} {
          &::after,
          &::before {
            background-image: set-svg($icon, $color);
          }
        }
      }
    }
  }

  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &-square {
    width: 100%;
    height: 100%;
    padding: 2rem 4rem 2rem 2rem;
    border: 1px solid $border-color;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    line-height: 1.4;
    background-color: $white;
    display: flex;
    align-items: center;
    &::first-line {
      line-height: 1;
    }
    &::after {
      width: 1em;
      position: absolute;
      top: calc(50% - 0.8em);
      right: 1em;
      content: '';
    }
    &--self,
    &--primary {
      &::after {
        @include setSvgIcon($arrow-right, $primary);
      }
      @media (hover: hover) {
        &:hover::after {
          @include setSvgIcon($arrow-right, $white);
        }
      }
    }
    &--blank {
      &::after {
        @include setSvgIcon($open-new-window, $primary);
      }
      @media (hover: hover) {
        &:hover::after {
          @include setSvgIcon($open-new-window, $white);
        }
      }
    }
    &--pdf {
      &::after {
        @include setSvgIcon($pdf, $primary);
      }
      @media (hover: hover) {
        &:hover::after {
          @include setSvgIcon($pdf, $white);
        }
      }
    }
    &--plus {
      &::after {
        @include setSvgIcon($plus, $primary);
      }
      @media (hover: hover) {
        &:hover::after {
          @include setSvgIcon($plus, $white);
        }
      }
    }
    &--primary {
      color: $primary;
      border-color: $primary;
    }
    &--anker {
      &::after {
        @include setSvgIcon($chevron-down, $primary);
      }
      @media (hover: hover) {
        &:hover::after {
          @include setSvgIcon($chevron-down, $white);
        }
      }
    }
    @media (hover: hover) {
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.btn-wrap {
  margin-top: 3.5rem;
  text-align: center;
  &:not(:last-child) {
    margin-bottom: 3.5rem;
  }
}
