@charset 'UTF-8';

@use './color' as *;

// font size
$btn-font-size-normal: 1.6rem !default;
$btn-font-size-small: $btn-font-size-normal * 0.8125 !default;
$btn-font-size-large: $btn-font-size-normal * 1.25 !default;

$btn-font-sizes: (
  'small': $btn-font-size-small,
  'normal': $btn-font-size-normal,
  'large': $btn-font-size-large,
) !default;

// border 太さ
$btn-border-width: 1px !default;

// padding 左右
$btn-x-padding-small: 0.6em !default;
$btn-x-padding-normal: 0.8em !default;
$btn-x-padding-large: 1.2em !default;

$btn-x-paddings: (
  'small': $btn-x-padding-small,
  'normal': $btn-x-padding-normal,
  'large': $btn-x-padding-large,
) !default;

// padding 上下
$btn-y-padding-small: 0.6em !default;
$btn-y-padding-normal: 0.93em !default;
$btn-y-padding-large: 1.2em !default;

$btn-y-paddings: (
  'small': $btn-y-padding-small,
  'normal': $btn-y-padding-normal,
  'large': $btn-y-padding-large,
) !default;

// background color
$btn-background-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'tertiary': $primary,
  'quaternary': $quaternary,
  'quinary': $quinary,
  'senary': $senary,
  'septenary': $septenary,
  'octonary': $octonary,
  'novenary': $novenary,
  'decenary': $decenary,
  'white': $white,
) !default;

$btn-hover-background-colors: (
  'primary': lighten($primary, 7.5%),
  'secondary': lighten($secondary, 7.5%),
  'tertiary': lighten($primary, 7.5%),
  'quaternary': lighten($quaternary, 7.5%),
  'quinary': lighten($quinary, 7.5%),
  'senary': lighten($senary, 7.5%),
  'septenary': lighten($septenary, 7.5%),
  'octonary': lighten($octonary, 7.5%),
  'novenary': lighten($novenary, 7.5%),
  'decenary': lighten($decenary, 7.5%),
  'white': $primary,
) !default;

// text color
$btn-colors: (
  'base': $white,
  'primary': $white,
  'secondary': $white,
  'tertiary': $white,
  'quaternary': $white,
  'quinary': $white,
  'senary': $white,
  'septenary': $white,
  'octonary': $white,
  'novenary': $white,
  'decenary': $white,
  'white': $primary,
) !default;

$btn-hover-colors: (
  'base': $white,
  'primary': $white,
  'secondary': $white,
  'tertiary': $white,
  'quaternary': $white,
  'quinary': $white,
  'senary': $white,
  'septenary': $white,
  'octonary': $white,
  'novenary': $white,
  'decenary': $white,
  'white': $white,
) !default;
