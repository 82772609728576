@charset 'UTF-8';
@use '../global/' as *;

.sidebar {
  width: 100%;
  margin-top: 6rem;
  padding-top: 3rem;
  border-top: 1px solid #ddd;
  @include media(lg) {
    width: 13%;
    min-width: 200px;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
  &-inner {
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 100%;
    @include media(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.4rem;
    @include media(lg) {
      font-size: 2.2rem;
    }
  }
  &-category {
    font-size: 1.2rem;
    font-family: $base-font-family-en-raleway;
    color: $secondary;
    margin-bottom: 2.55rem;
    padding-bottom: 2.55rem;
    border-bottom: 1px solid $border-color;
  }
  &-list {
    font-size: 1.4rem;
    li {
      padding: 0.7rem 0 0.7rem 1.9rem;
      position: relative;
      &::before {
        content: '';
        width: 1rem;
        height: 0.1rem;
        background-color: $border-color;
        position: absolute;
        top: 50%;
        left: 0;
      }
      &.current {
        a {
          color: $secondary;
        }
        &::before {
          background-color: $secondary;
        }
      }
    }
  }
}
