@charset 'UTF-8';

@use '../global/' as *;
@use 'sass:math';

// 子メニューの背景色
$child-background-color: #d9e1ee;

// wrapper
.global-nav-wrapper {
  height: 100%;
  background-color: $quaternary;
  overflow: auto;
  @include media(lg) {
    height: auto;
    background-color: transparent;
    overflow: visible;
  }
}

// container
.global-nav-container {
  //@include makeContainer;
}

// navigation
ul.global-nav {
  display: block;
  position: relative;
  @include media(lg) {
    display: flex;
    justify-content: flex-end;
  }
}

// メインメニュー
li.gnav-parent {
  position: relative;
  &::before {
    content: '';
    background-color: $border-color;
    display: block;
    width: calc(100% - 2.9rem * 2);
    height: 1px;
    position: absolute;
    left: 2.9rem;
    bottom: 0;
    @include media(lg) {
      display: none;
    }
  }
  &.hover {
    a.gnav-local-link {
      &::after {
        width: 100%;
      }
    }
  }
  &:last-child {
    span {
      &::before {
        display: none;
      }
    }
  }
}

a.gnav-local-link {
  display: block;
  font-size: 1.6rem;
  padding: 1.7rem 2.9rem;
  position: relative;
  @include media(lg) {
    padding: 0 1.5rem;
    letter-spacing: 0.12em;
  }
  .active & {
    span {
      &::after {
        background-image: set-svg($chevron-up, $secondary);
        @include media(lg) {
          background-image: none;
        }
      }
    }
  }
  @include media(lg) {
  &::after {
    content: '';
    display: block;
    background: $secondary;
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    transition: width $base-transition;
  }
  &:hover {
    color: $secondary;
    &::after {
      width: 100%;
    }
  }
  }
  // ボーダーと Caret の処理
  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @include media(lg) {
      display: block;
    }
    &::after {
      content: '';
      @include setSvgIcon($chevron-right, $secondary, 2rem);
      @include media(lg) {
        display: none;
      }
    }
  }
  &.js-trigger {
    span {
      &::after {
        @include setSvgIcon($chevron-down, $secondary, 2rem);
        @include media(lg) {
          display: none;
        }
      }
    }
  }
}

// 子メニュー
//
.gnav-child-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height $base-transition;
  @include media(lg) {
    position: absolute;
    top: 80px;
    width: max-content;
    max-width: 270px;
    background: $white;
    transition: max-height $base-transition 0.2s;
    box-shadow: 0 0.6rem 1.4rem rgba($primary, 0.15);
    border-radius: 12px;
  }
}

.gnav-child-container {
  padding: 0rem 2.9rem 2rem;
  @include media(lg) {
    margin: 0 auto;
    padding: 3.4rem 3.8rem;
    width: max-content;
  }
}

.gnav-child-inner {
  // @include media(lg) {
  //   padding: 1rem;
  //   @for $i from 1 through $grid-columns {
  //     &.col-#{$i} {
  //       width: percentage(math.div($i, 12));
  //     }
  //   }
  // }
}

ul.gnav-child-list {
  font-size: 1.4rem;
}

a.gnav-child-link {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  position: relative;
  // Caret
  &::before {
    content: '';
    width: 1rem;
    height: 1px;
    background-color: $secondary;
    margin-right: 0.8rem;
    @include media(lg) {
      display: none;
    }
  }
}
