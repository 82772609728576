@charset 'UTF-8';
@use './global/' as sys;

.btn-anchor {
  background-color: sys.$white;
  border: 1px solid sys.$secondary;
  border-radius: 6px;
  color: sys.$secondary;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.8em;
  padding: 0.8em 1em;

  &::after {
    content: '';
    background-image: sys.set-svg(sys.$caret-down, sys.$secondary);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    transform: translateY(0);
    transition: transform sys.$base-transition;
    width: 1em;
    height: 1em;
  }
  @media (hover: hover) {
    &:hover {
      //background-color: sys.$primary;
      //color: sys.$secondary;
      &::after {
        transform: translateY(0.5rem);
      }
    }
  }

  //@include sys.media(md) {
  //  margin-bottom: -.2em;
  //  padding: 1em 1.5em 1.8em;
  //  width: 100%;
  //}
  //@include sys.media(lg) {
  //  margin-bottom: -1em;
  //}
}

.btn-disabled {
  pointer-events: none;
}
